import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { borderRadius } from '../../../tokens/theme';

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
`;

const StyledLabel = styled(motion.label)`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-colour);
  background: var(--accent-colour);
  border-radius: ${props => borderRadius.asymmetric[props.$asymRadius]};
  // border-top: 1px solid rgba(255,255,255,0.05);
  transition: all 0.2s ease;
  cursor: ${props => props.$disabled ? 'not-allowed' : 'default'};
  opacity: ${props => props.$disabled ? 0.6 : 1};
    margin-right: 10px;

  
  ${props => props.$floating && css`
    &:hover {
      animation: ${floatAnimation} 2s ease infinite;
    }
  `}

  ${props => props.$required && css`
    &::after {
      content: '*';
      color: var(--error);
      margin-left: 4px;
    }
  `}

  ${props => props.$error && css`
    color: var(--error);
    border-color: var(--error);
  `}
`;

const HelperText = styled.span`
  font-size: 12px;
  color: ${props => props.$error ? 'var(--error)' : 'var(--text-secondary)'};
  margin-top: 4px;
  transition: color 0.2s ease;
`;

const FormLabel = ({
  children,
  required = false,
  error = false,
  helperText,
  disabled = false,
  htmlFor,
  floating = true,
  asymRadius = 'sm',
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <StyledLabel
        htmlFor={htmlFor}
        $required={required}
        $error={error}
        $disabled={disabled}
        $floating={floating}
        $asymRadius={asymRadius}
        {...props}
      >
        {children}
      </StyledLabel>
      {helperText && (
        <HelperText $error={error}>
          {helperText}
        </HelperText>
      )}
    </div>
  );
};

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  htmlFor: PropTypes.string,
  floating: PropTypes.bool,
  asymRadius: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  className: PropTypes.string,
};

export default FormLabel;
