import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {validateAccessToken} from "../api/validateToken";
import {postAccessRequest} from "../api/postAccessRequest";
import {useContext} from "react";
import {AuthContext} from "../../authentication";
import {useHandleError} from "../../error-handling/js/hooks/index.js";


export const useUserAccess = () => {

    const { externalLogin, isAuthenticated, logout } = useContext(AuthContext);
    const { showErrorToast } = useHandleError();

    
    const validate = useMutation({
        mutationFn: (data) => validateAccessToken(data),
        onSuccess: (data) => {
            let loginAttempt;
            loginAttempt = externalLogin(data.data.authorisation, data.data.user)
    
            if(!loginAttempt) {
                toast.warning('Failed to log you in!');
            }
    
            toast.success('Successfully Logged In')
        },
        onError: (error) => {
            showErrorToast(error.response.data.error_message || 'There has been an error')
            if(isAuthenticated) logout()
        }
    })

    const submit = useMutation({
        mutationFn: (data) => postAccessRequest(data),
        onSuccess: (data) => {
            const res = data.data
            console.log(res)
            if(res.status == 'success') {
                toast.success(res.message, {
                    autoClose: false
                })
            }
        },
        onError: (data) => {
            showErrorToast(data.response.data.error_message)
        }
    })

    return {
        validate,
        submit
    }

}