import React from 'react'
import * as Sentry from '@sentry/react';
import styled from 'styled-components'
import { BUILD_VERSION } from '../../js/Helper'
import { Link, useParams } from 'react-router-dom'
import LogoComponent from '../../components/ui/LogoComponent'
import {AuthenticatedFooterLinks} from './AuthenticatedFooterLinks'
import {UnauthenticatedFooterLinks} from './UnauthenticatedFooterLinks'
import { StyledTitle } from '../../components/ui/typography'

const FooterContainer = styled.footer`

    display: grid;
    grid-template-areas: "middle";
    place-items: center;
    position: relative;
    padding: 20px 0px;
    padding-bottom: 100px;
    font-size: small;
    box-sizing: border-box;
    /* border-top: solid 1px rgba(255,255,255,0.05); */
    background-color: var(--default-primary-colour);
    backdrop-filter: blur(10px);
    z-index: 21;    
    color: var(--text-colour);

    
    @media screen and (min-width: 768px) {
        
    }
    
    @media screen and (min-width: 1024px) {
        padding: 40px 0px;
        grid-template-areas: "left middle right";
        grid-template-columns: 1fr 1fr 1fr;

        #company {
            grid-area: middle;
        }
    }

    @media screen and (min-width: 1920px) {
        height: 350px;
    }
`

const Section = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: small;
    text-align: center;
    box-sizing: border-box;
    z-index: 21;

    a {
        color: white;
        font-weight: bold;
    }
    
    `

const LogoArea = styled.div`
`

const CompanyDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1024px){
    }
`

const LinksContainer = styled.div`
`

const Logo = styled.img`
    width: 100px;
`

const Text = styled.p`
    cursor: pointer;
    max-width : 350px;
    margin:0px;
`

export const Footer = () => {

    const params = useParams();
    const slug = params.slug;


  return (
    <FooterContainer id='footer' name={'footer'}>
        <Section id='company'>
            <LogoArea>
                <LogoComponent variant={'light'} />
            </LogoArea>
            <CompanyDetails>
                <Text>Nocturnal Lifestyle & Technology Ltd</Text>
                <Text>Trading as Nocturnal Lifestyle</Text>
                <br></br>
                {/* <AuthenticatedFooterLinks /> */}
                {/* <UnauthenticatedFooterLinks /> */}
                <br></br>
                <Link to={`/order-finder/${slug}`}>Order Finder</Link>
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
                <Link to={'/ticket-terms-conditions'}>Terms & Conditions</Link>
                <br></br>
                {/* <Text onClick={() => {
                  Sentry.showReportDialog({ eventId: Sentry.lastEventId() });
                }}>Report an Issue</Text> */}
            </CompanyDetails>
        </Section>
        <Section id='socials'>
            {/* <LoginButton login/> */}
        </Section>
        <Section id='terms'>
            <StyledTitle text="Contact Us"/>
            <Text>If you experience any issues using our platform please contact us via any of the channels below</Text>
            <div>
            <Text>
                <a href="instagram://user?username=nocturnallifestyle" style={{ color: 'inherit', textDecoration: 'none' }}>
                    Instagram: @nocturnallifestyle
                </a>
            </Text>
            </div>
        </Section>
  
    </FooterContainer>

  )
}
