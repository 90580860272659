import React, { useState } from 'react';
import styled from 'styled-components';
import { useApiQuery } from '../../../../../hooks/customHooks';
import CheckoutModalContent from '../../components/CheckoutModalContent/CheckoutModalContent';
import { PiSpinner } from 'react-icons/pi';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { updateTheme } from '../../../../../features/themes/js/utils';
import Image from '../../../../../atomic/atoms/Image';
import AnimatedElement from '../../../../../atomic/atoms/AnimatedElement/AnimatedElement';
import { createExpressCheckoutSession } from '../../../../../features/ecommerce/services/stripe/api/expressCheckout';
import { useBasket } from '../../../basket/context/BasketContext';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* padding: 20px; */
  background: var(--background-colour);
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const CheckoutContainer = styled.div`
  background: var(--surface-colour);
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 480px;
`;

const LoadingSpinner = styled(motion(PiSpinner))`
  font-size: 2rem;
  color: var(--text-colour);
`;

const ErrorMessage = styled.div`
  color: var(--error-colour);
  padding: 16px;
  border-radius: 8px;
  background: var(--error-colour-10);
  margin-top: 16px;
`;

const spinnerVariants = {
  animate: {
    rotate: 360,
    transition: {
      duration: 1,
      ease: "linear",
      repeat: Infinity
    }
  }
};

const CheckoutPage = () => {
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [error, setError] = useState(null);
  const [checkoutBasket, setCheckoutBasket] = useState(null);
  const { basketId: encodedBasketId } = useParams();

  const { basket, setBasketState } = useBasket();
  
  // Decode the base64 basketId
  const decodedBasketId = React.useMemo(() => {
    try {
      return atob(encodedBasketId);
    } catch (error) {
      console.error('Failed to decode basketId:', error);
      setError('Invalid basket ID');
      return null;
    }
  }, [encodedBasketId]);
    
  const { isLoading } = useApiQuery(
    decodedBasketId ? `basket/${decodedBasketId}?with=user` : null,
    (data) => {
      console.log('Basket fetched successfully:', data);
      setBasketState(data.data);
      updateTheme(data.data.basketable.theme)
    },
    (error) => {
      console.error('Failed to fetch basket:', error);
      setError(error.message);
    }
  );

  const handleCheckoutSubmit = async (data) => {
    try {
      const response = await createExpressCheckoutSession({
        amount: basket.total,
        product_id: basket.orderItems[0]?.productId, // Assuming single product checkout
        quantity: basket.orderItems[0]?.quantity,
        customerDetails: data,
        basketableId: basket.basketable.id,
        basketableType: 'event',
        basketId: basket.id
      });

      console.log('Checkout session created:', response);

      if (!response?.intentId) {
        throw new Error('Failed to create checkout session - No client secret received');
      }

      // Store the basket and payment details
      setCheckoutBasket(response.basket);
      setClientSecret(response.clientSecret);
      setPaymentIntentId(response.intentId);
      setIsPaymentStep(true);

      return { status: 'success' };
    } catch (error) {
      console.error('Express checkout failed:', error);
      throw error;
    }
  };

  const handlePaymentSuccess = (paymentIntent) => {
    // Implement your payment success logic here
    console.log('Payment successful:', paymentIntent);
  };

  const handlePaymentError = (error) => {
    console.error('Payment failed:', error);
    setError(error.message);
  };

  console.log('image', basket)

  if (isLoading) {
    return (
      <PageContainer>
        <ContentWrapper>
          <LoadingSpinner variants={spinnerVariants} animate="animate" />
        </ContentWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ContentWrapper>
        <CheckoutContainer>
          {error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : (
            <>
            <AnimatedElement delay={0.2} duration={0.8}>
              <Image src={basket?.basketable?.image?.cdnUrl} alt={basket?.basketable?.name} borderRadius="50px 0px" style={{marginBottom: '20px'}} />
            </AnimatedElement>

            <CheckoutModalContent
              isCheckoutPage={true}
              isPaymentStep={isPaymentStep}
              onCheckoutSubmit={handleCheckoutSubmit}
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentError={handlePaymentError}
              clientSecret={clientSecret}
              paymentIntentId={paymentIntentId}
              amount={basket?.total}
              basketableType={basket?.data?.basketable?.type}
              basket={isPaymentStep ? checkoutBasket : basket}
            />
            </>
            
          )}
        </CheckoutContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

export default CheckoutPage; 