import React, { useState, useCallback } from 'react';
import { useModal } from '../../../../atomic/molecules/Modal/ModalContext';
import { createExpressCheckoutSession } from '../../../../features/ecommerce/services/stripe/api/expressCheckout';
import useProductCalculations from './useProductCalculations';
import { useBasket } from '../../basket/context/BasketContext';
import CheckoutModalContent from '../../checkout/components/CheckoutModalContent/CheckoutModalContent';

export const useBuyNowModal = ({ onSubmit, product, quantity = 1, basketableId, basketableType = 'event', basketId }) => {
  const { openModal, closeModal } = useModal();
  const { refreshBasket } = useBasket();
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [error, setError] = useState(null);
  const [basket, setBasket] = useState(null);

  const { totalPriceWithFees } = useProductCalculations(product, quantity);

  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      await onSubmit({ 
        paymentIntent,
        basket
      });
      closeModal();
    } catch (error) {
      console.error('Failed to process purchase:', error);
      setError(error.message);
    }
  };

  const handlePaymentError = (error) => {
    console.error('Payment failed:', error);
    setError(error.message);
  };

  const handleCheckoutSubmit = async (data) => {
    try {
      const response = await createExpressCheckoutSession({
        amount: totalPriceWithFees,
        product_id: product.id,
        quantity,
        customerDetails: data,
        basketableId: basketableId,
        basketableType: basketableType,
        basketId: basketId
      });

      console.log('Checkout session created:', response);

      if (!response?.intentId) {
        throw new Error('Failed to create checkout session - No client secret received');
      }

      // Store the basket and payment details
      setBasket(response.basket);
      setClientSecret(response.clientSecret);
      setPaymentIntentId(response.intentId);
      setIsPaymentStep(true);

      // Update modal content to show payment form
      openModal({
        title: 'Complete Payment',
        content: (
          <CheckoutModalContent
            isPaymentStep={true}
            onPaymentSuccess={handlePaymentSuccess}
            onPaymentError={handlePaymentError}
            clientSecret={response.clientSecret}
            paymentIntentId={response.intentId}
            amount={totalPriceWithFees}
            basketableType={basketableType}
            basket={response.basket}
          />
        )
      });
      
      return { status: 'success' };
    } catch (error) {
      console.error('Express checkout failed:', error);
      throw error;
    }
  };

  const openBuyNowModal = useCallback(() => {
    // Reset all state when opening the modal
    setIsPaymentStep(false);
    setClientSecret(null);
    setPaymentIntentId(null);
    setError(null);
    setBasket(null);

    // Refresh basket before opening modal
    // refreshBasket();

    // Open the modal with checkout form
    openModal({
      title: 'Complete your information',
      content: (
        <CheckoutModalContent
          isPaymentStep={false}
          onCheckoutSubmit={handleCheckoutSubmit}
        />
      )
    });
  }, [product, quantity, basketableType, basketableId, refreshBasket]);

  return {
    openBuyNowModal,
    error
  };
}; 