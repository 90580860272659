import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

/**
 * ImageWrapper component that handles the container styling and aspect ratio
 */
const ImageWrapper = styled(motion.div)`
  position: relative;
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || 'auto'};
  overflow: ${props => props.$overflow || 'hidden'};
  border-radius: ${props => props.$borderRadius || '0'};
  box-shadow: 10px 5px 20px rgba(0,0,0,0.4);
  
  
  ${props => props.$aspectRatio && `
    aspect-ratio: ${props.$aspectRatio};
  `}
`;

/**
 * StyledImage component that handles the image styling and positioning
 */
const StyledImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: ${props => props.$objectFit || 'cover'};
  object-position: ${props => props.$objectPosition || 'center'};
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease;
`;

/**
 * Placeholder component shown while image is loading
 */
const Placeholder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--placeholder-colour, #f0f0f0);
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Error component shown when image fails to load
 */
const ErrorContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--error-background, #fee2e2);
  color: var(--error-text, #991b1b);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  font-size: 14px;
`;

/**
 * Image component with loading states, error handling, and animations
 * @param {string} src - Source URL of the image
 * @param {string} alt - Alt text for the image
 * @param {string} width - Width of the image container
 * @param {string} height - Height of the image container
 * @param {string} aspectRatio - Aspect ratio of the image (e.g., "16/9")
 * @param {string} objectFit - CSS object-fit property
 * @param {string} objectPosition - CSS object-position property
 * @param {string} borderRadius - Border radius of the image container
 * @param {string} overflow - Overflow behavior of the container
 * @param {node} placeholder - Custom placeholder component
 * @param {node} errorComponent - Custom error component
 * @param {boolean} lazy - Whether to use lazy loading
 * @param {function} onLoad - Callback when image loads successfully
 * @param {function} onError - Callback when image fails to load
 */
const Image = ({
  src,
  alt,
  width,
  height,
  aspectRatio,
  objectFit = 'cover',
  objectPosition = 'center',
  borderRadius,
  overflow,
  placeholder,
  errorComponent,
  lazy = true,
  onLoad,
  onError,
  style,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const handleLoad = (event) => {
    setIsLoaded(true);
    setHasError(false);
    onLoad?.(event);
  };

  const handleError = (event) => {
    setHasError(true);
    setIsLoaded(false);
    onError?.(event);
  };

  return (
    <ImageWrapper
      $width={width}
      $height={height}
      $aspectRatio={aspectRatio}
      $borderRadius={borderRadius}
      $overflow={overflow}
      style={style}
      {...props}
    >
      <AnimatePresence>
        {!isLoaded && !hasError && (
          <Placeholder
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {placeholder || null}
          </Placeholder>
        )}
      </AnimatePresence>

      <StyledImage
        src={src}
        alt={alt}
        loading={lazy ? 'lazy' : undefined}
        onLoad={handleLoad}
        onError={handleError}
        $isLoaded={isLoaded}
        $objectFit={objectFit}
        $objectPosition={objectPosition}
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoaded ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      />

      <AnimatePresence>
        {hasError && (
          <ErrorContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {errorComponent || 'Failed to load image'}
          </ErrorContainer>
        )}
      </AnimatePresence>
    </ImageWrapper>
  );
};



export default Image;
