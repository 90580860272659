import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { FullPageFlexCenter } from '../../../../layout/styles'
import { AuthContext } from '../../../authentication';
import OrdersPage from '../OrdersPage/OrdersPage';
import { Form } from '../../../../components/forms/formStyles';
import { ButtonComponent } from '../../../../components/ui/buttons';
import LogoComponent from '../../../../components/ui/LogoComponent';
import { useUserAccess } from '../../hooks/useUserAccess';
import useUpdateTheme from '../../../themes/js/hooks/useUpdateTheme';
import { Divider } from '../../../../components/ui/Divider';
import { Paragraph } from '../../../../components/ui/typography';
import { StandardSection } from '../../../../layout/sections/StandardSection';
import { Footer } from '../../../../layout/components/Footer';
import styled from 'styled-components';


const Container = styled.section`
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`

export const AccessAccountPage = () =>  {


    const { isAuthenticated, externalLogin } = useContext(AuthContext);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const to = searchParams.get('to');
    
    const { validate, submit } = useUserAccess();

    useEffect(() => {
        if(token || to) {
            validate.mutate({token: token, to: to});
        }
    }, []);


    if(isAuthenticated) {
        return <OrdersPage />
    }

    const onSubmit = (data) => {
        submit.mutate(data)
    }

  return (
    <>
     <Container>
        <LogoComponent />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Paragraph>Use this form if you're unable to access your account, or have not recieved tickets you have purchased.</Paragraph>
                <Paragraph>We will attempt to find your account by your email and/or phone number and send you a link to access your account.</Paragraph>
                <Divider />
                <input required {...register('firstName')} type='text' placeholder='First Name'/>
                <input required {...register('lastName')} type='text' placeholder='Last Name'/>
                <input required {...register('email')} type='email' placeholder='Email'/>
                <input required {...register('date_of_purchase')} type='date' placeholder='Date of Purchase'/>
                <input required {...register('mobile')} type='number' placeholder='Mobile'/>
                <Divider />
                <ButtonComponent
                variant={'solid'}
                text={'Submit Details'}
                isLoading={submit.isLoading}
                />
            </Form>
        </Container>
        <Footer/>
    </>
       

  )
}

