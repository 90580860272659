import React, { useContext } from 'react'
import { UserInfoBox } from './UserInfoBox'
import { CustomerInfoForm } from '../../ecommerce/checkout/components/CustomerInfoForm'
import { gearsEmoji, padlockUnlockedEmoji, personSilihouette } from '../../../js/emojis'
import { AuthContext } from '../AuthContext';
import { useDrawer } from '../../../js/hooks/context/DrawerContext';

function DefaultUserInfoBox({ onGearsClick }) {
    const { user, logout } = useContext(AuthContext);
    const { openDrawer } = useDrawer();

  return (
        <UserInfoBox
        message={user?.email}
        icon={personSilihouette}
        actions={[
            {icon: gearsEmoji, onClick: () => openDrawer(onGearsClick)},
            {icon: padlockUnlockedEmoji, onClick: () => logout()}
        ]}
        /> 
            )
}

export default DefaultUserInfoBox