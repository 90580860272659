import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { borderRadius } from '../../../tokens/theme';
import { 
  premiumInteractive, 
  premiumAccent, 
  premiumError 
} from '../../../tokens/animations';

const InputWrapper = styled.div`
  position: relative;

`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  width: 20px;
`;

const IconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 20px;
  color: var(--text-colour);
  height: 20px;
  flex-shrink: 0;
  ${premiumInteractive}

  ${props => props.$isFocused && css`
    ${premiumAccent}
  `}

  ${props => props.$error && css`
    ${premiumError}
  `}
`;

const StyledInput = styled(motion.input)`
  width: 100%;
  /* background: var(--primary-colour); */
  /* border: 1px solid rgba(255,255,255,0.1); */
  border-radius: 8px;
  padding: 12px 16px;
  color: var(--text-colour);
  font-size: 16px;
  ${premiumInteractive}

  &::placeholder {
    color: var(--text-colour);
    opacity: 0.3;
  }

  ${props => props.$hasIcon && `
    padding-left: 48px;
  `}

  ${props => props.$error && css`
    border-color: var(--error-colour);
    &:focus {
      border-color: var(--error-colour);
      box-shadow: 0 0 0 1px var(--error-colour),
                  0 5px 15px rgba(181, 89, 89, 0.2);
    }
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: var(--primary-colour);
    border-color: rgba(191, 181, 147, 0.05);
  }
`;

const ErrorMessage = styled(motion.div)`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.9;
`;

const Input = forwardRef(({
  type = 'text',
  placeholder,
  error = false,
  errorMessage,
  icon,
  asymRadius = 'sm',
  className,
  autoComplete,
  ...props
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const hasIcon = Boolean(icon);
  
  return (
    <InputWrapper name='input' className={className}>
      <StyledInput
        ref={ref}
        type={type}
        placeholder={placeholder}
        $error={error}
        $hasIcon={hasIcon}
        $isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        autoComplete={autoComplete}
        {...props}
      />
      <AnimatePresence>
        {icon && (
          <>
            <IconContainer>
              <IconWrapper
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ 
                  opacity: isFocused ? 1 : 0.7,
                  scale: 1
                }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
                $isFocused={isFocused}
                $error={error}
              >
                {icon}
              </IconWrapper>
            </IconContainer>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {error && errorMessage && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </AnimatePresence>
    </InputWrapper>
  );
});

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  icon: PropTypes.node,
  asymRadius: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  className: PropTypes.string,
  autoComplete: PropTypes.string,
};

Input.displayName = 'Input';

export default Input;
