import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../../../atomic/atoms/Box/Box';
import Progress from '../../atoms/Progress/Progress';

const StyledBox = styled(Box)`
  padding: 1.5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-grow: 1;
  min-width: 50%;
  flex-direction: column;
  gap: 1.5rem;
  background: ${({ theme }) => theme?.baseColors?.primary 
    ? `${theme.baseColors.primary}1A`
    : 'rgba(42, 45, 58, 0.1)'
  };
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 0.5px solid var(--border-colour);
  box-shadow: 
    0 4px 24px -1px rgba(0, 0, 0, 0.2),
    0 2px 8px -1px rgba(0, 0, 0, 0.1),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
`;

const Title = styled.h3`
  color: var(--text-colour);
  font-size: 1.25rem;
  margin: 0;
`;

const ProgressCard = ({ 
  title,
  items,
  className,
  asymRadius,
  reverse,
  radius 
}) => {

  if(!items) return null;

  return (
    <StyledBox
      className={className}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
    >
      {title && <Title>{title}</Title>}
      {items.map((item, index) => (
        <Progress
          key={item.id || index}
          value={item.sold}
          max={item.availability}
          label={item.name}
          showValue={item.showValue}
          threshold={item.threshold}
        />
      ))}
    </StyledBox>
  );
};

ProgressCard.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    label: PropTypes.string,
    showValue: PropTypes.bool,
    threshold: PropTypes.number
  })).isRequired,
  className: PropTypes.string,
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  radius: PropTypes.string
};

export default ProgressCard; 