import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '../../../atomic/atoms/form-elements/Input/Input';
import EmailInput from '../../../atomic/atoms/form-elements/EmailInput/EmailInput';
import FormLabel from '../../../atomic/atoms/form-elements/FormLabel/FormLabel';
import Button from '../../../atomic/atoms/Button/Button';
import axios from 'axios';
import { useApiQuery } from '../../../hooks/customHooks';
import { updateTheme } from '../../themes/js/utils';
import { useParams } from 'react-router-dom';
import { CreditCardIcon, UserIcon, DownloadIcon, TicketIcon, AlertCircleIcon } from 'lucide-react';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';
import { API_URL } from '../../../js/Helper';
import { LoadingAnimationComponent } from '../../../components/ui/LoadingAnimationComponent';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 1.5rem;
    color: white;
`;

const StyledInput = styled(Input)`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`;

const StyledEmailInput = styled(EmailInput)`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
  width: 100%;
  gap: 8px;
`;

const TicketsContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TicketCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const TicketInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: var(--primary-color);
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
  }
`;

const OrdersInfo = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const OrderReference = styled.div`
  font-family: monospace;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  color: var(--text-primary);
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(255, 59, 48, 0.1);
  border: 1px solid rgba(255, 59, 48, 0.2);
  border-radius: 8px;
  color: white;
  margin-bottom: 1rem;
  width: 100%;

  svg {
    color: rgb(255, 59, 48);
  }
`;

const InstagramLink = styled.a`
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
`;

const HelpText = styled.div`
  text-align: center;
  color: white;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const OrderFinder = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    last_four_digits: ''
  });
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(null);
  const [loadingText, setLoadingText] = useState('Searching...');
  const params = useParams();
  const slug = params.slug;

  const loadingStates = [
    'Searching...',
    'Checking transactions...',
    'Verifying user details...',
    'Looking up tickets...',
  ];

  useEffect(() => {
    let currentIndex = 0;
    let interval;

    if (loading) {
      interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % loadingStates.length;
        setLoadingText(loadingStates[currentIndex]);
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      setLoadingText('Searching...');
    };
  }, [loading]);

  const { data: eventData } = useApiQuery(
    `event/${slug}?with=business`,
    (data) => {
      updateTheme(data?.data?.theme);
    },
    (error) => {
      console.error('Error fetching event:', error);
    },
    { enabled: !!slug }
  );

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    // Clear error when user starts typing
    if (error) setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.post(API_URL + '/orders/find/by-payment', {
        ...formData,
        event_id: eventData?.data?.id
      });
      if (response.data.status === 'error') {
        setError(response.data.message);
        setOrderData(null);
      } else {
        setOrderData(response.data.data.orders);
        setError(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred while searching for your order.');
      setOrderData(null);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (url, filename) => {
    try {
      // Start download indicator
      const downloadButton = document.createElement('a');
      downloadButton.href = url;
      downloadButton.download = `ticket-${filename}.png`;
      downloadButton.target = '_blank';
      downloadButton.rel = 'noopener noreferrer';
      downloadButton.setAttribute('download', ''); // Force download attribute
      document.body.appendChild(downloadButton);
      downloadButton.click();
      document.body.removeChild(downloadButton);
    } catch (error) {
      console.error('Download failed:', error);
      // If all else fails, open in new tab
      window.open(url, '_blank');
    }
  };

  if(!eventData) return <LoadingAnimationComponent />

  const renderForm = () => (
    <StyledForm onSubmit={handleSubmit}>
      <FormGroup>
        <FormLabel htmlFor="firstName">First Name</FormLabel>
        <StyledInput
          icon={<UserIcon />}
          type="text"
          id="firstName"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
          placeholder={'Enter your first name'}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="lastName">Last Name</FormLabel>
        <StyledInput
          icon={<UserIcon />}
          type="text"
          id="lastName"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
          placeholder={'Enter your last name'}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="email">Email</FormLabel>
        <StyledEmailInput
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          placeholder={'Enter your email'}
        />
      </FormGroup>

      <FormGroup>
        <FormLabel htmlFor="lastFourDigits">Last 4 Digits of Card</FormLabel>
        <StyledInput
          icon={<CreditCardIcon />}
          type="number"
          inputMode="numeric"
          id="last_four_digits"
          name="last_four_digits"
          value={formData.last_four_digits}
          onChange={handleChange}
          required
          maxLength="4"
          pattern="[0-9]{4}"
          placeholder={'Enter the last 4 digits of your card'}
        />
      </FormGroup>

      {error && (
        <>
          <ErrorMessage>
            <AlertCircleIcon size={20} />
            <span>{error}</span>
          </ErrorMessage>
          <HelpText>
            Need help? Message us on{' '}
            <InstagramLink 
              href="https://www.instagram.com/nocturnallifestyle"
              onClick={(e) => {
                e.preventDefault();
                // Try to open Instagram app first
                window.location.href = 'instagram://user?username=nocturnallifestyle';
                
                // Fallback to web after a small delay (in case app doesn't open)
                setTimeout(() => {
                  window.location.href = 'https://www.instagram.com/nocturnallifestyle';
                }, 500);
              }}
            >
              @nocturnallifestyle
            </InstagramLink>
          </HelpText>
        </>
      )}

      <Button
        type="submit"
        disabled={loading}
        variant="primary"
        fullWidth
        style={{
          width: '100%'
        }}
      >
        {loading ? loadingText : 'Find Order'}
      </Button>
    </StyledForm>
  );

  const renderTickets = () => (
    <>
      <OrdersInfo>
        <SuccessMessage>{orderData.count > 1 ? 'Multiple orders found!' : 'Order found!'}</SuccessMessage>
        {orderData.references.map((reference, index) => (
          <OrderReference key={index}>
            Order #{index + 1}: {reference}
          </OrderReference>
        ))}
      </OrdersInfo>
      <TicketsContainer>
        <ImpactTitle subHeader="Your Tickets" style={{marginBottom: '1rem'}} />
        <Paragraph style={{marginBottom: '1rem'}}>Tip: Long press the download button to save the ticket to your device.</Paragraph>
        {orderData.tickets.map((ticket, index) => (
          <TicketCard key={ticket.uuid}>
            <TicketInfo>
              <TicketIcon size={20} />
              <span>Ticket #{index + 1}</span>
            </TicketInfo>
            <DownloadButton 
              href={ticket.cdnUrl}
              onClick={(e) => {
                e.preventDefault();
                handleDownload(ticket.cdnUrl, `${index + 1}`);
              }}
            >
              <DownloadIcon size={16} />
              Download
            </DownloadButton>
          </TicketCard>
        ))}
        <Button
          variant="secondary"
          fullWidth
          onClick={() => {
            setOrderData(null);
            setError(null);
          }}
          style={{ marginTop: '1rem' }}
        >
          Search Another Order
        </Button>
      </TicketsContainer>
    </>
  );

  return (
    <Container>
      <ImpactTitle main={'Find Your Order'}  />
      <ImpactTitle 
        subHeader={`${eventData?.data?.name}`} 
        header={eventData?.data?.business?.name} 
        style={{marginBottom: '2rem'}}
      />
      
      {!orderData && (
        <Paragraph style={{marginBottom: '2rem'}}>
          If you have not received your tickets, first check your spam/junk folder. If they are not there then complete the form below.
        </Paragraph>
      )}

      {!orderData ? renderForm() : renderTickets()}
    </Container>
  );
};

export default OrderFinder; 