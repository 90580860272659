import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import { EventConfirmationLeft, EventConfirmationRight } from '../EventConfirmation/EventConfirmation';
import TwoColumnLayout from '../../../../atomic/templates/TwoColumnLayout/TwoColumnLayout';
import { useBasket } from '../../../ecommerce/basket/context/BasketContext';

const Container = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
`;

/**
 * BaseConfirmation Component
 * Acts as a router/controller for different types of confirmation pages
 * Determines which confirmation component to render based on the basketable type
 * and provides a consistent layout template
 */
const BaseConfirmation = () => {
  const { id } = useParams();
  const location = useLocation();
  const { emptyBasket } = useBasket();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get('status');

  useEffect(() => {
    emptyBasket();
  }, []);

  const renderContent = (side) => {
        return side === 'left' 
          ? <EventConfirmationLeft id={id} status={status} />
          : <EventConfirmationRight id={id} />;
  };

  return (
    // <Container>
      <TwoColumnLayout
        leftContent={renderContent('left')}
        rightContent={renderContent('right')}
        height={'full'}
        mobileLayout="leftOnly"
        leftRatio={50}
        rightRatio={50}
      />
    // </Container>
  );
};

BaseConfirmation.propTypes = {
  // Add any additional props if needed
};

export default BaseConfirmation;
