import React, {useContext} from 'react'
import {OrdersPageContainer} from './OrdersPage.Style'
import LogoComponent from '../../../../components/ui/LogoComponent'
import {AuthContext} from '../../../authentication'
import {getUsersOrders} from '../../api/orders';
import {LoadingAnimationComponent} from '../../../../components/ui/LoadingAnimationComponent';
import {StandardListLayout} from '../../../../layout/ListLayouts/StandardListLayout';
import {RectangleCardComponent} from '../../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent';
import {StyledTitle} from '../../../../components/ui/typography/StyledTitle';
import {
  RectangleCardImage
} from '../../../../components/ui/cards/RectangleCardComponent/components/styles/rectangleCardImage.style';
import {useDrawer} from '../../../../js/hooks/context/DrawerContext';
import Order from '../../../../pages/user/Order';
import {LoginForm} from '../../../authentication/components/LoginForm';
import DefaultUserInfoBox from '../../../authentication/components/DefaultUserInfoBox';
import {Paragraph} from '../../../../components/ui/typography';
import {Footer} from '../../../../layout/components/Footer';
import {clearSearchParams} from '../../../../js/Helper';
import {LoginPage} from '../../../authentication/pages/LoginPage';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";


function OrdersPage() {

  const { isAuthenticated, user } = useContext(AuthContext);
  const { openDrawer } = useDrawer();
  const { showErrorPage, showErrorToast, reportError } = useHandleError()


  if(!user) {
    return <LoginPage redirect={'/orders'} />
  }

  const searchParams = new URLSearchParams(location.search)
  const resError = searchParams.get('error');

  if(resError) {
    showErrorToast('Error downloading files');
    clearSearchParams()
  }

  const { data, isLoading, error } = getUsersOrders(user?.id);

  
  if(isLoading) {
    return <LoadingAnimationComponent />
  }
  
  if(error) {
    reportError(error.message)
    return showErrorPage(error.message)
  }

  const handleClick = (item) => {
    openDrawer(<Order order={item} id={item.id} />)
  }
  
  

  return (
    <>
    <OrdersPageContainer>
      <LogoComponent />
    { isAuthenticated ? ( 
      <>
      <DefaultUserInfoBox onGearsClick={<Paragraph>Soon you will be able to update your account details here.</Paragraph>} />
      <StyledTitle title={'Your Orders '} />
      </>
    ) : (
      <LoginForm />
    )
  }
      <StandardListLayout>
          {data && data.data.map((item, index) => {
            
            return (
              
              <RectangleCardComponent
              key={index}
              image={<RectangleCardImage source={item.image}  />}
              title={item.name}
              description={`Order Total: ${item.amount}`}
              handleClick={() => handleClick(item)}
              
              />
              )
            })}
        </StandardListLayout>
    </OrdersPageContainer>
    <Footer/>
    </>

  )
}

export default OrdersPage