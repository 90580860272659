import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacing,
} from '../../tokens/typography';

/**
 * Base paragraph styles
 */
const baseStyles = css`
  margin: 0;
  font-family: var(--font-tommy);
  color: ${props => props.$color || 'var(--text-primary)'};
  text-align: ${props => props.$align || 'left'};
  font-size: ${props => props.$size ? fontSizes[props.$size] : fontSizes.base};
  font-weight: ${props => props.$weight ? fontWeights[props.$weight] : fontWeights.regular};
  line-height: ${props => props.$lineHeight ? lineHeights[props.$lineHeight] : lineHeights.tight};
  letter-spacing: ${props => props.$spacing ? letterSpacing[props.$spacing] : letterSpacing.normal};
  
  /* Handle text wrapping */
  ${props => props.$wrap === 'nowrap' && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  
  ${props => props.$wrap === 'balance' && css`
    text-wrap: balance;
  `}
  
  /* Character count limit with ellipsis */
  ${props => props.$maxChars && css`
    display: inline-block;
    max-width: 100%;
  `}
  
  /* Line clamp */
  ${props => props.$maxLines && css`
    display: -webkit-box;
    -webkit-line-clamp: ${props.$maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
  
  /* Hyphenation */
  ${props => props.$hyphenate && css`
    hyphens: auto;
    hyphenate-limit-chars: 6 3 3;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;
    hyphenate-limit-zone: 8%;
  `}
  
  /* Drop cap */
  ${props => props.$dropCap && css`
    &::first-letter {
      float: left;
      font-size: 3.5em;
      font-weight: ${fontWeights.bold};
      line-height: 0.8;
      margin-right: 0.1em;
      color: var(--primary-colour);
    }
  `}
  
  /* Columns */
  ${props => props.$columns && css`
    column-count: ${props.$columns};
    column-gap: 2em;
    column-rule: 1px solid var(--border-color);
    
    @media (max-width: 768px) {
      column-count: 1;
    }
  `}
`;

const StyledParagraph = styled.p`
  ${baseStyles}
`;

/**
 * ReadMore button styling
 */
const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: var(--primary-colour);
  font-family: var(--font-tommy);
  font-size: 0.9em;
  font-weight: ${fontWeights.medium};
  padding: 0;
  margin-left: 0.5em;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

/**
 * Paragraph component with advanced text features
 * 
 * @param {Object} props - Component props
 * @param {string} [props.size] - Font size
 * @param {string} [props.weight] - Font weight
 * @param {string} [props.color] - Text color
 * @param {string} [props.align] - Text alignment
 * @param {string} [props.lineHeight] - Line height
 * @param {string} [props.spacing] - Letter spacing
 * @param {number} [props.maxChars] - Maximum characters before truncating
 * @param {number} [props.maxLines] - Maximum lines before truncating
 * @param {string} [props.wrap] - Text wrapping behavior
 * @param {boolean} [props.hyphenate] - Enable hyphenation
 * @param {boolean} [props.dropCap] - Enable drop cap
 * @param {number} [props.columns] - Number of columns
 * @param {boolean} [props.expandable] - Allow expanding truncated text
 */
const Paragraph = ({
  children,
  size,
  weight,
  color,
  align,
  lineHeight,
  spacing,
  maxChars,
  maxLines,
  wrap,
  hyphenate,
  dropCap,
  columns,
  expandable = false,
  className,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);
  const fullText = children?.toString() || '';

  // Handle character limit
  const displayText = maxChars && !isExpanded
    ? fullText.slice(0, maxChars) + (fullText.length > maxChars ? '...' : '')
    : fullText;

  // Check if text is actually truncated
  useEffect(() => {
    if (textRef.current && (maxLines || maxChars)) {
      const isTrunc = textRef.current.scrollHeight > textRef.current.clientHeight ||
                     (maxChars && fullText.length > maxChars);
      setIsTruncated(isTrunc);
    }
  }, [children, maxLines, maxChars, fullText]);

  return (
    <>
      <StyledParagraph
        ref={textRef}
        $size={size}
        $weight={weight}
        $color={color}
        $align={align}
        $lineHeight={lineHeight}
        $spacing={spacing}
        $maxChars={!isExpanded && maxChars}
        $maxLines={!isExpanded && maxLines}
        $wrap={wrap}
        $hyphenate={hyphenate}
        $dropCap={dropCap}
        $columns={columns}
        className={className}
        {...props}
      >
        {typeof children === 'string' ? children : children}
      </StyledParagraph>
      
      {expandable && isTruncated && (
        <ReadMoreButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Show less' : 'Read more'}
        </ReadMoreButton>
      )}
    </>
  );
};

Paragraph.propTypes = {
  size: PropTypes.oneOf(Object.keys(fontSizes)),
  weight: PropTypes.oneOf(Object.keys(fontWeights)),
  color: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  lineHeight: PropTypes.oneOf(Object.keys(lineHeights)),
  spacing: PropTypes.oneOf(Object.keys(letterSpacing)),
  maxChars: PropTypes.number,
  maxLines: PropTypes.number,
  wrap: PropTypes.oneOf(['normal', 'nowrap', 'balance']),
  hyphenate: PropTypes.bool,
  dropCap: PropTypes.bool,
  columns: PropTypes.number,
  expandable: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Paragraph;
