import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { getApplicationConfig } from '../config/applicationMap';
import FloatingNav from '../../atomic/molecules/FloatingNav/FloatingNav';
import { generateMenuConfig, baseNavigationConfig } from '../config/basePortalConfig';
import { NavigationProvider } from '../../contexts/NavigationContext';
import styled from 'styled-components';
import { Footer } from '../../layout/components/Footer';

const PortalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
`;

const DevelopmentNotice = styled.div`
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  padding: 12px 24px;
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 0.3px;
  line-height: 1.5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 30px;
  
  a {
    color: white;
    text-decoration: underline;
    font-weight: 600;
    
    &:hover {
      opacity: 0.9;
    }
  }
`;

const PasswordModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PasswordForm = styled.div`
  background: ${({ theme }) => theme?.baseColors?.primary 
    ? `${theme.baseColors.primary}1A`
    : 'rgba(42, 45, 58, 0.95)'
  };
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 400px;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &.shake {
    animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s;

  &:focus {
    border-color: var(--accent-colour);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background: var(--accent-colour);
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

const ErrorMessage = styled.div`
  color: var(--error-colour);
  margin-top: 10px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 6px;
  
  svg {
    font-size: 1.1rem;
  }
`;

const AttemptCount = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  margin-top: 8px;
  text-align: center;
`;

const PortalLayout = () => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [shake, setShake] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const navigate = useNavigate();

  const checkAuthentication = () => {
    const authData = localStorage.getItem('portal_auth');
    if (!authData) return false;

    try {
      const { expiry } = JSON.parse(authData);
      if (new Date(expiry) > new Date()) {
        return true;
      }
      localStorage.removeItem('portal_auth');
      return false;
    } catch (e) {
      localStorage.removeItem('portal_auth');
      return false;
    }
  };

  const handleAuthentication = (e) => {
    e?.preventDefault();
    
    if (password === import.meta.env.VITE_APP_PORTAL_PASSWORD) {
      const expiry = new Date();
      expiry.setDate(expiry.getDate() + 7);
      
      localStorage.setItem('portal_auth', JSON.stringify({
        authenticated: true,
        expiry: expiry.toISOString()
      }));
      
      setShowPasswordModal(false);
      setError('');
      setAttempts(0);
    } else {
      setAttempts(prev => prev + 1);
      setError('Incorrect password. Please try again.');
      setShake(true);
      setTimeout(() => setShake(false), 500);

      // Clear password field after incorrect attempt
      setPassword('');
    }
  };

  useEffect(() => {
    if(import.meta.env.NODE_ENV !== 'production') {
      setShowPasswordModal(false);
      return;
    }

    const isAuthenticated = checkAuthentication();
    if (!isAuthenticated) {
      setShowPasswordModal(true);
    }
  }, []);

  return (
    <NavigationProvider>
      {showPasswordModal && (
        <PasswordModal>
          <PasswordForm className={shake ? 'shake' : ''}>
            <h2 style={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}>
              Portal Access Required
            </h2>
            <form onSubmit={handleAuthentication}>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                autoFocus
                disabled={attempts >= 5}
              />
              <Button 
                type="submit"
                disabled={attempts >= 5}
              >
                {attempts >= 5 ? 'Too many attempts' : 'Submit'}
              </Button>
              {error && (
                <ErrorMessage>
                  <span>⚠️</span> {error}
                </ErrorMessage>
              )}
              {attempts > 0 && attempts < 5 && (
                <AttemptCount>
                  {5 - attempts} attempts remaining
                </AttemptCount>
              )}
              {attempts >= 5 && (
                <ErrorMessage style={{ textAlign: 'center', marginTop: '20px' }}>
                  Maximum attempts reached. Please contact your administrator.
                </ErrorMessage>
              )}
            </form>
          </PasswordForm>
        </PasswordModal>
      )}
      <PortalContainer>
        <DevelopmentNotice>
          🚧 This portal is currently under development. <br />
          If you encounter any issues, please contact your <br />
          account manager.
        </DevelopmentNotice>
        <Outlet />
      </PortalContainer>
      <Footer />
    </NavigationProvider>
  );
};

export default PortalLayout; 